.baseFilter :global(.selectInput__control) {
  @apply pl-2 pr-2;
}

.baseFilter :global(.selectInput__dropdown-indicator) {
  @apply pl-1;
}

.baseFilter :global(.selectInput__clear-indicator) {
  @apply pr-1;
}

.baseFilter :global(.selectInput__menu) {
  @apply text-xs;
}
