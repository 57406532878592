.base {
  @apply z-50 flex-col space-y-2 border border-r bg-white p-4;
}
/* sidebar state */
.isOpen {
  @apply absolute flex h-full w-64 md:relative;
}
.isClosed {
  @apply hidden w-24 md:flex;
}
/* sidebar overlay */
.overlay {
  @apply absolute left-0 top-0 z-20 flex h-full w-full cursor-pointer bg-gray-900 bg-opacity-50 md:hidden;
}
/* header */
.header {
  @apply relative flex items-center justify-center py-1;
}
.logo {
  @apply my-auto h-14 w-48 text-brand;
}
.smallLogoWrapper {
  @apply flex items-center justify-center rounded-lg bg-gray-100 p-4;
}
.smallLogo {
  @apply text-brand;
}
.openCloseTrigger {
  @apply absolute -right-8 z-50 cursor-pointer rounded-lg bg-white p-1 transition-colors;
  @apply border border-gray-300 text-gray-500 hover:border-gray-400 hover:text-gray-900;
}
/* hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* navbar menu */
.navbar {
  @apply no-scrollbar flex flex-1 flex-col space-y-1 overflow-y-auto text-sm;
}
.navbarIsOpen {
  @apply px-0;
}
.navbarIsClosed {
  @apply px-2.5;
}
.linkLabel {
  @apply flex-1 truncate font-medium;
}

.link {
  @apply flex cursor-pointer items-center space-x-2 rounded-lg py-2 transition-colors hover:bg-gray-100 hover:text-gray-900;
  &[aria-current='page'] {
    @apply bg-brand text-white;
  }
}
.linkIsOpen {
  @apply px-4;
}
.linkIsClosed {
  @apply justify-center px-2;
}
.linkIconWrapper {
  @apply flex items-center justify-center;
}
.linkIcon {
  @apply h-6 w-6;
}
.sidebarHeight {
  /*
  120px is [header height + navigator height]
  (don't judge my obsession by this shity statement)
  */
  @apply h-[calc(100%-36px)] lg:h-full;
}
.link {
  @apply flex cursor-pointer items-center space-x-2 rounded-lg py-2 transition-colors hover:bg-gray-100 hover:text-gray-900;
  &[aria-current='page'] {
    @apply bg-brand text-white;
  }
}
.linkIsOpen {
  @apply px-4;
}
.linkIsClosed {
  @apply relative justify-center px-2;
}
.linkIconWrapper {
  @apply flex items-center justify-center;
}
.linkIcon {
  @apply h-6 w-6;
}
