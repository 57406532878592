.trigger {
  @apply flex appearance-none items-center space-x-1 rounded-md bg-white bg-opacity-5 py-2 font-medium;
  -webkit-appearance: none !important;
}
.flag {
  @apply h-6 w-6 object-cover;
}
.language {
  @apply w-4 text-left text-sm uppercase;
}
.wrapper {
  @apply flex flex-col space-y-1;
}
.languageItem {
  @apply flex cursor-pointer items-center justify-between rounded-lg p-4 hover:bg-gray-50;
}
.contentWrapper {
  @apply flex flex-1 items-center space-x-4;
}
.icon {
  @apply h-6 w-6 object-contain;
}
.title {
  @apply text-sm;
}
.checkIcon {
  @apply text-gray-900;
}
