.base {
  @apply w-full max-w-full rounded-lg text-gray-900;
  @apply placeholder-gray-400 transition-colors duration-200 ease-out;
  &:disabled {
    @apply pointer-events-none border-gray-300 bg-gray-100;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @apply border-gray-400;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
}
.underlineBase {
  @apply bg-transparent text-brand placeholder-gray-400 focus:outline-none md:text-4xl;
  @apply border-b leading-none;
  @apply px-1 py-0 !important;

  &:focus {
    @apply mb-[-1px] border-b-2;
  }
}
/* variant */
.hero {
  @apply base border border-gray-300 !important;
  &:focus {
    @apply focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 !important;
  }
}
.heroError {
  @apply base border border-red-500 !important;
  &:focus {
    @apply focus:border-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 !important;
  }
}
.underline {
  @apply underlineBase border-brand;
}
.underlineError {
  @apply underlineBase border-red-500;
}
