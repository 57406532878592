.base {
  @apply fixed inset-0 z-[100] overflow-y-auto;
}
/* overlay */
.overlayWrapper {
  @apply flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0;
}
.overlay {
  @apply fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity;
}
/* overlay transition */
.overlayEnter {
  @apply duration-300 ease-out;
}
.overlayEnterFrom {
  @apply opacity-0;
}
.overlayEnterTo {
  @apply opacity-100;
}
.overlayLeave {
  @apply duration-200 ease-in;
}
.overlayLeaveFrom {
  @apply opacity-100;
}
.overlayLeaveTo {
  @apply opacity-0;
}
/* modal content */
.wrapper {
  @apply relative inline-block transform overflow-visible rounded-2xl bg-white text-left align-bottom transition-all;
  @apply px-4 pb-4 pt-5 sm:my-8 sm:w-full sm:p-6 sm:align-middle;
}
.position {
  @apply hidden sm:inline-block sm:h-screen sm:align-middle;
}
.headerWrapper {
  @apply flex items-center;
}
.title {
  @apply flex-1 text-lg font-bold leading-6 text-gray-900;
}
.closeTrigger {
  @apply cursor-pointer rounded-lg bg-white p-1 transition-colors;
  @apply border border-gray-300 text-gray-500 hover:border-gray-400 hover:text-gray-900;
}
.childrenWrapper {
  @apply pt-6;
}
/* modal content transition */
.modalEnter {
  @apply duration-300 ease-out;
}
.modalEnterFrom {
  @apply translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95;
}
.modalEnterTo {
  @apply translate-y-0 opacity-100 sm:scale-100;
}
.modalLeave {
  @apply duration-200 ease-in;
}
.modalLeaveFrom {
  @apply translate-y-0 opacity-100 sm:scale-100;
}
.modalLeaveTo {
  @apply translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95;
}
