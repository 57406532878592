.footer {
  @apply relative block bg-brand pb-20 text-white;
}
.linksTitle {
  @apply mb-2 text-base font-bold;
}
.linksGrid {
  @apply grid grid-cols-2 gap-0 md:grid-cols-2 md:gap-4;
}
.linksList {
  @apply space-y-2;
}
.linkItem {
  @apply truncate text-sm text-gray-200 hover:text-white hover:underline;
}
.paymentMethodsGrid {
  @apply grid w-full grid-cols-3 gap-4 md:grid-cols-2 xl:grid-cols-3;
}
