.badge {
  @apply inline-flex items-center rounded-full px-3 py-0.5 font-medium;
}
.grayBadge {
  @apply badge bg-gray-100 text-gray-800;
}
.redBadge {
  @apply badge bg-red-100 text-red-800;
}
.blueBadge {
  @apply badge bg-blue-100 text-blue-800;
}
.yellowBadge {
  @apply badge bg-yellow-100 text-yellow-800;
}
.pinkBadge {
  @apply badge bg-pink-100 text-pink-800;
}
.indigoBadge {
  @apply badge bg-indigo-100 text-indigo-800;
}
.purpleBadge {
  @apply badge bg-purple-100 text-purple-800;
}
.greenBadge {
  @apply badge bg-green-100 text-green-800;
}
