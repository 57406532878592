.disabledLabel {
  @apply pointer-events-none border-gray-300 text-gray-300;
  &:hover {
    @apply border-gray-300;
  }
}
.disabled {
  @apply pointer-events-none border-gray-300 bg-gray-300 text-gray-300;
  &:hover {
    @apply border-gray-300;
  }
}
.base {
  @apply h-4 w-4 cursor-pointer rounded;
  &:focus {
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0;
  }
  &:disabled {
    @apply disabledLabel;
  }
}
.hero {
  @apply base border-gray-300 text-blue-600;
  &:focus {
    @apply focus:border-blue-600 focus:ring-blue-400;
  }
}
.error {
  @apply base border-red-500 text-red-500;
  &:focus {
    @apply focus:border-red-600 focus:ring-red-400;
  }
}
