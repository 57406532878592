.base {
  @apply flex flex-col items-start rounded-md px-3 py-2 font-medium;
}
.active {
  @apply text-green-600;
}
.pending {
  @apply text-yellow-600;
}
.missing {
  @apply text-yellow-600;
}
.title {
  @apply text-xs;
}
.status {
  @apply text-sm font-bold;
}
