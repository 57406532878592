.wrapper {
  @apply min-h-0 lg:min-h-800px;
  @apply flex w-full flex-col space-y-5 rounded-xl bg-gray-50 p-6 md:p-8 lg:w-72;
}
.title {
  @apply block text-xl font-semibold text-gray-700;
}

/* steps */
.stepsWrapper {
  @apply flex flex-1 flex-col justify-between space-y-2;
}
.step {
  @apply flex flex-1 flex-col space-y-2;

  &:last-child .stepDescription {
    @apply border-transparent;
  }
}
.stepTitle {
  @apply flex items-center space-x-4 font-medium text-gray-700;

  & h4 {
    /* to prevent wire instructions page from overriding h4 styles */
    @apply text-base !important;
  }
}
.stepDescription {
  @apply ml-4 block min-h-[1rem] flex-1 border-l pl-8 text-sm text-gray-600;
  @apply border-gray-300;
}
