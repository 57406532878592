.header {
  @apply text-xl font-bold text-primary;
}

.tableBodyCell {
  @apply truncate border border-lightGrey px-1 py-2 text-center text-xs leading-6;
}

.tableBodyCell a {
  @apply cursor-pointer text-primary;
}

.tableBody {
  @apply min-h-[350px];
}

.tableHeadCell {
  @apply h-9 border border-lightGrey text-xs;
}

.tableHead {
  @apply w-full max-w-[available] text-xs font-bold capitalize;
}

.tableHead input:not([type='checkbox']),
.tableHead select {
  @apply w-full px-2 py-1.5;
}

.tableHead tr:first-of-type th {
  @apply border border-solid border-lightGrey bg-white bg-clip-padding;
}

.table {
  @apply w-full min-w-full table-fixed;
}

.tableAndPagination {
  @apply bg-white bg-opacity-80 p-6 shadow sm:rounded;
}

.tableHeadRowWithCheckbox th:first-of-type {
  @apply w-8;
}

.tableHeadRowWithCheckbox th:first-of-type input {
  /* TODO: get the color from the browser as a variable and use that instead */
  @apply rounded border-[#71717a];
}
