/* spaces */
.baseSpaces {
  @apply px-6 py-3 text-sm;
}
.moreLeftSpaces {
  @apply pl-12;
}
.moreRightSpaces {
  @apply pr-20;
}

.inputWrapper {
  @apply relative w-full overflow-hidden;
}
.inputWrapperUnit {
  @apply pr-6;
}
.unit {
  @apply absolute right-0 top-0 h-full rounded-r-md border border-gray-300 bg-gray-50 px-3 py-2 text-center;
}
.unitError {
  @apply border border-red-500 bg-red-100 text-red-500;
}
