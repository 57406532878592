.base :global(.selectInput__control) {
  @apply relative rounded-lg border border-gray-300 py-1 pl-4 pr-4 transition-none;
  &:hover {
    @apply hover:border-gray-300;
  }
}
.moreRightSpaces :global(.selectInput__control) {
  @apply pr-11;
}
.errorInput :global(.selectInput__control) {
  @apply border-red-500;
  &:hover {
    @apply hover:border-red-500;
  }
}
.base :global(.selectInput__control--is-focused) {
  @apply border-blue-600 outline-none ring-2 ring-blue-400;
}
.base :global(.selectInput__input) input {
  box-shadow: unset !important;
}
.base :global(.selectInput__single-value) + div {
  margin: 2px 1px !important;
}
.base :global(.selectInput__control--is-disabled) {
  @apply bg-gray-100;
}
.base :global(.selectInput__placeholder),
.base :global(.selectInput__dropdown-indicator) {
  @apply text-gray-400;
}
.base :global(.selectInput__single-value--is-disabled) {
  @apply text-gray-900;
}
.errorInput :global(.selectInput__control--is-focused) {
  @apply border-red-600 outline-none ring-2 ring-red-400;
}
.base :global(.selectInput__value-container) {
  @apply p-0;
}
.base :global(.selectInput__single-value) {
  @apply m-0;
}
.base :global(.selectInput__dropdown-indicator) {
  @apply p-0 pl-3;
}
.base :global(.selectInput__clear-indicator) {
  @apply cursor-pointer p-0 pr-3;
}
.base :global(.selectInput__menu) {
  @apply overflow-hidden rounded-lg border border-gray-300 text-base shadow-lg;
}
