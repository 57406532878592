/* spaces */
.baseSpaces {
  @apply px-6 py-2;
}
.moreLeftSpaces {
  @apply pl-12;
}
.moreRightSpaces {
  @apply pr-20;
}
/* custom date picker */

.wrapper {
  @apply z-20 flex border-none font-[inherit] text-base font-semibold;
}

.wrapper :global(.react-datepicker__day) {
  @apply rounded-md;
}
.wrapper :global(.react-datepicker__month-container),
.wrapper :global(.react-datepicker__header),
.wrapper :global(.react-datepicker__time),
.wrapper
  :global(
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
  ) {
  @apply w-auto border-none bg-transparent;
}

.wrapper :global(.react-datepicker__month-container) {
  @apply flex-1;
}

.wrapper :global(.react-datepicker__day--keyboard-selected) {
  @apply bg-blue-600 text-white;
}

.wrapper
  :global(
    .react-datepicker__day--today:not(.react-datepicker__day--keyboard-selected)
  ) {
  @apply rounded-md border border-blue-600;
}

.wrapper :global(.react-datepicker__time-container) {
  @apply w-auto border-l border-l-gray-200;
}

.wrapper:global(.react-datepicker__header) {
  @apply border-none bg-transparent;
}

.wrapper :global(.react-datepicker__day-name) {
  @apply text-gray-500;
}

.wrapper :global(.react-datepicker__day-name),
.wrapper :global(.react-datepicker__day),
.wrapper :global(.react-datepicker__time-name) {
  @apply w-8;
}
.headerWrapper {
  @apply mb-2 flex items-center space-x-1 px-2;
}
.arrow {
  @apply cursor-pointer;
}
