.base {
  @apply relative focus-within:text-gray-900;
}
/* label */
.label {
  @apply block whitespace-nowrap text-sm font-medium text-gray-700;
}
.childrenWrapper {
  @apply relative mt-1;
}
.inlineChildrenWrapper {
  @apply flex items-center justify-end gap-6;
}
/* header element */
.headerNode {
  @apply absolute right-0 top-0;
}
/* addon base */
.addon {
  @apply pointer-events-none absolute top-3 z-10 flex items-center text-sm text-gray-500;
}
/* addon before  */
.addonBefore {
  @apply addon left-2 px-3;
}
/* addon after  */
.addonAfter {
  @apply addon right-2 pr-3;
}
.addonAfterMoreSpace {
  @apply right-8;
}
/* error state */
.errorIcon {
  @apply absolute right-4 top-3 bg-white text-red-500;
}
.errorMessage {
  @apply absolute left-0 mt-1 w-full truncate break-words text-left text-xs font-medium text-red-500;
}
/* disabled state */
.disabled {
  @apply cursor-default;
}
/* hint for the input */
.hintMessage {
  @apply absolute mt-1 w-full truncate break-words text-left text-xs font-medium text-gray-500;
}
