.wrapper {
  @apply grid grid-cols-1 place-items-center gap-6 pb-10 md:gap-10;
}
.navbarWrapper {
  @apply w-full;
}
.contentWrapper {
  @apply flex w-full flex-col rounded-xl bg-white lg:flex-row;
}
.childWrapper {
  @apply grid min-w-full gap-10 px-4 md:min-w-max md:max-w-6xl md:px-8;

  &.hasSidebar {
    @media screen(lg) {
      min-width: 70vw;
      margin: 0 auto;
    }
  }
}
.footerWrapper {
  @apply grid gap-8;
}
