/* spaces */
.baseSpaces {
  @apply px-6 py-2;
}
.moreLeftSpaces {
  @apply pl-12;
}
.moreRightSpaces {
  @apply pr-20;
}
/* custom date picker */
.wrapper {
  @apply w-full;
}
.popper {
  @apply z-20;
}
.popper :global(.react-datepicker__header) {
  @apply border-b border-gray-300 bg-transparent;
}
.popper :global(.react-datepicker) {
  @apply w-full rounded-lg border border-gray-300 font-[inherit] text-base font-semibold shadow-lg;
}
.popper :global(.react-datepicker__day-name),
.popper :global(.react-datepicker__day),
.popper :global(.react-datepicker__time-name) {
  @apply w-8;
}
.headerWrapper {
  @apply mb-2 flex items-center space-x-1 px-2;
}
.arrow {
  @apply cursor-pointer;
}
